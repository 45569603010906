import React from 'react';
import { useTheme } from 'styled-components';
import { Grid, InputAdornment, Typography } from '@sezzle/sezzle-mui';
import { RevenueCalculatorProps } from './types';
import {
  inputTextStyle,
  StyledRevenueContainer,
  StyledHeaderTextContainer,
  StyledInputContainer,
  StyledCalculationContainer,
  StyledCalculateButton,
  StyledTextField,
  StyledResultContainer,
  StyledResultHeader,
  StyledResultSubHeader,
  StyledBodyDisclaimer,
} from './styles';
import SezzleButton from '../CTA/SezzleButton';
import { CTASelections } from '../types/enums';

const RevenueCalculator = ({
  header,
  body,
  aovFactor,
  marketingFactor,
  incrementalSales,
  checkoutShare,
  disclaimer,
  backgroundColor,
}: RevenueCalculatorProps) => {
  const theme = useTheme();

  const [aov, setAov] = React.useState(0);
  const [tpv, setTpv] = React.useState(0);
  const [revenue, setRevenue] = React.useState(0);

  function calculateRevenue() {
    const marketing = tpv * checkoutShare * marketingFactor;
    const incrementalConversions = tpv * checkoutShare - marketing;
    const nonIncrementalConversions =
      ((tpv * checkoutShare) / (aov * aovFactor)) *
      (1 - incrementalSales) *
      (aov * (aovFactor - 1));

    const totalImpact = Math.round(
      incrementalConversions + nonIncrementalConversions + marketing
    ).toLocaleString();
    setRevenue(totalImpact);
  }

  function parseInput(e) {
    const inputValue = parseInt(e.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      return inputValue;
    }

    return 0;
  }

  return (
    <StyledRevenueContainer
      data-component-type="revenue-container"
      sx={{
        backgroundColor:
          backgroundColor && theme.backgroundColors[backgroundColor]
            ? theme.backgroundColors[backgroundColor]
            : 'inherit',
      }}
    >
      <StyledHeaderTextContainer>
        <Typography variant="HeadlineSmall">{header} </Typography>
        <Typography variant="BodyRegular">{body}</Typography>
      </StyledHeaderTextContainer>

      <StyledCalculationContainer
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
        >
          <StyledInputContainer>
            <StyledTextField
              fullWidth
              type="number"
              placeholder="Average Order Value"
              value={aov === 0 ? '' : aov}
              onInput={(e) => setAov(parseInput(e))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { style: inputTextStyle },
              }}
              sx={{
                '& .MuiFilledInput-root': {
                  height: '36px',
                  'border-radius': '4px',
                },
                '& .MuiFilledInput-input': {
                  paddingTop: '8px',
                },
              }}
            />
            <StyledTextField
              fullWidth
              type="number"
              placeholder="Annual Online Revenue"
              value={tpv == 0 ? '' : tpv}
              onInput={(e) => setTpv(parseInput(e))}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { style: inputTextStyle },
              }}
              sx={{
                '& .MuiFilledInput-root': {
                  height: '36px',
                  'border-radius': '4px',
                },
                '& .MuiFilledInput-input': {
                  paddingTop: '8px',
                },
              }}
            />
          </StyledInputContainer>

          <SezzleButton
            style={CTASelections.PrimaryButton}
            buttonSize="medium"
            onClick={calculateRevenue}
            displayText="Calculate Results"
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
        >
          <StyledResultContainer
            container
            direction="column"
          >
            <StyledResultHeader variant="HeadlineSmall">
              {revenue ? `$${revenue}` : '$0'}
            </StyledResultHeader>

            <StyledResultSubHeader variant="BodyRegular">
              Incremental Revenue Lift
            </StyledResultSubHeader>
          </StyledResultContainer>
        </Grid>
      </StyledCalculationContainer>

      <Grid container>
        <StyledBodyDisclaimer variant="BodyDisclaimer">
          {disclaimer}
        </StyledBodyDisclaimer>
      </Grid>
    </StyledRevenueContainer>
  );
};

export default RevenueCalculator;
